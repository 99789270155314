<template>
    <div>
        <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
                <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">Home</h2>
                    <div class="breadcrumb-wrapper col-12">
                        
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Dashboard</a>
                                </li>
                                <li class="breadcrumb-item active">
                                    Home
                                </li>
                            </ol> 
                    </div>
                </div>
            </div>
        </div>

        <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">
            <div class="form-group breadcrum-right">
                <div class="dropdown">
                    <button class="btn-icon btn btn-primary btn-round btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="feather icon-settings"></i></button>
                    <div class="dropdown-menu dropdown-menu-right"><a class="dropdown-item" href="#">Chat</a><a class="dropdown-item" href="#">Email</a><a class="dropdown-item" href="#">Calendar</a></div>
                </div>
            </div>
        </div>
    </div>

    <div class="content-body">
        <section id="dashboard-ecommerce">
            <div class="row">
                <div class="col-lg-4 col-sm-6 col-12" v-if="hasPermission('home-total-payment')">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-primary p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-dollar-sign text-primary font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/payment" class="primary">
                              <h2 class="text-bold-700 mt-1">{{ payments | formatMoney }}</h2>
                              <p class="mb-0">Toal Payments</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12" v-if="hasPermission('home-successful-payment')">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-success p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-dollar-sign text-success font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/payment" class="success">
                              <h2 class="text-bold-700 mt-1">{{ successfull_payments | formatMoney }}</h2>
                              <p class="mb-0">Successfull Payments</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12" v-if="hasPermission('home-pending-payments')">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-warning p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-dollar-sign text-warning font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/payment" class="text-warning">
                              <h2 class="text-bold-700 mt-1">{{ pending_payments | formatMoney }}</h2>
                              <p class="mb-0">Pending Payments</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div class="row" v-if="hasPermission('view-payment-graph')">
                <div class="col-lg-12 col-md-6 col-12">
                    <div class="card">
                        <div class="card-header d-flex justify-content-between align-items-end">
                            <h4 class="card-title">Payment Summary By MDA's</h4>
                            <p class="font-medium-5 mb-0"><i class="feather icon-settings text-muted cursor-pointer"></i></p>
                        </div>
                        <div class="card-content">
                            <div class="card-body pb-0">
                                <vue-chart type="bar" :data="paymentChart"></vue-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </section>
   
   </div>
    </div>
</template>

<script>
import VueChart from 'vue-chart-js'
    export default {
        components: { VueChart },
        mounted(){
            if(this.$route.query.reload)
                window.location = window.location.pathname;
            axios.get('/api/dashboard')
                .then(response => response.data)
                .then(response => {
                    this.payments = response.payments;
                    this.successfull_payments = response.successfull_payments;
                    this.pending_payments = response.pending_payments;
                    this.paymentChart.labels = response.labels;
                    this.paymentChart.datasets[0].data = response.data; 
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                })

            axios.get('/api/user/preference/pre-requisite')
                .then(response => response.data)
                .then(response => {
                    this.color_themes = response.color_themes;
                    this.directions = response.directions;
                    this.sidebar = response.sidebar;
                    this.locales = response.locales;
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                })
            this.userRole = this.getRole()[0];
        },
        methods: {
            hasPermission(permission){
                return helper.hasPermission(permission);
            },
            hasRole(role){
                return helper.hasRole(role);
            },
            getRole(role){
              return helper.getRole(role);
            },
            logout(){
                helper.logout().then(() => {
                    this.$store.dispatch('resetAuthUserDetail');
                    this.$router.push('/login')
                })
            },
            updatePreference(){
                this.preferenceForm.post('/api/user/preference')
                    .then(response => {
                        toastr.success(response.message);

                        $('#theme').attr('href','/css/colors/'+this.preferenceForm.color_theme+'.css');

                        if(this.user_preference.direction != this.preferenceForm.direction || this.user_preference.sidebar != this.preferenceForm.sidebar || this.user_preference.locale != this.preferenceForm.locale)
                            location.reload();
                    })
                    .catch(error => {
                        helper.showErrorMsg(error);
                    })
            }
        },
        data() {
            return {
                paymentChart: {
                    labels: [],
                    datasets: [
                        {
                            label: 'Payments',
                            backgroundColor: 'rgb(153,145,244)',
                            borderColor: 'rgb(153,145,244)',
                            data: []
                        }
                    ]
                },
                payments: 0,
                successfull_payments: 0,
                pending_payments: 0,
                color_themes: [],
                directions: [],
                sidebar: [],
                locales: [],
                role: [],
                userRole: '',
                preferenceForm: new Form({
                    color_theme: helper.getConfig('user_color_theme') || helper.getConfig('color_theme'),
                    direction: helper.getConfig('user_direction') || helper.getConfig('direction'),
                    locale: helper.getConfig('user_locale') || helper.getConfig('locale'),
                    sidebar: helper.getConfig('user_sidebar') || helper.getConfig('sidebar')
                },false),
                user_preference: {
                    color_theme: helper.getConfig('user_color_theme') || helper.getConfig('color_theme'),
                    direction: helper.getConfig('user_direction') || helper.getConfig('direction'),
                    locale: helper.getConfig('user_locale') || helper.getConfig('locale'),
                    sidebar: helper.getConfig('user_sidebar') || helper.getConfig('sidebar')
                }
            }
        },
        computed: {
        },
        filters: {
          momentDateTime(date) {
            return helper.formatDateTime(date);
          },
          moment(date) {
            return helper.formatDate(date);
          },
          formatMoney(value){
            return helper.formatMoney(value);
          }
        },
    }
</script>
<style>
    .shw-rside{
        width: 500px;
    }
</style>