var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "row flexbox-container" }, [
    _c("div", { staticClass: "col-xl-8 col-10 justify-content-center" }, [
      _c("div", { staticClass: "card bg-authentication rounded-0 mb-0" }, [
        _c("div", { staticClass: "row m-0" }, [
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-12 col-12 p-0" }, [
            _c("div", { staticClass: "card rounded-0 mb-0 p-2" }, [
              _c("div", { staticClass: "card-headers pt-50 pb-1" }, [
                _c("div", { staticClass: "card-title" }, [
                  _c("h4", { staticClass: "mb-0 text-left" }, [
                    _vm._v("MAKE PAYMENT")
                  ]),
                  _vm._v(" "),
                  _vm.remark == "success"
                    ? _c(
                        "h4",
                        { staticClass: "mb-0 text-right text-success" },
                        [
                          _c("i", { staticClass: "feather icon-check" }),
                          _vm._v(" INVOICE HAS BEEN PAID")
                        ]
                      )
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("div", { staticClass: "card-content" }, [
                _c("div", { staticClass: "card-body pt-0" }, [
                  _c(
                    "form",
                    {
                      attrs: { id: "registerform" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.storePayment.apply(null, arguments)
                        },
                        keydown: function($event) {
                          return _vm.paymentForm.errors.clear(
                            $event.target.name
                          )
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-xl-6 col-md-12 col-12" },
                          [
                            _c("div", { staticClass: "form-group" }, [
                              _c("h2", { staticClass: "mb-0" }, [
                                _vm._v(
                                  "INVOICE NO: " +
                                    _vm._s(_vm.paymentForm.reference)
                                )
                              ])
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-xl-6 col-md-12 col-12" },
                          [
                            _c("div", { staticClass: "form-group" }, [
                              _c("h2", { staticClass: "mb-0 text-right" }, [
                                _c("i", { staticClass: "fa fa-building" }),
                                _vm._v(" " + _vm._s(_vm.mda))
                              ])
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-xl-4 col-md-12 col-12" },
                          [
                            _c("span", [_vm._v("Invoice Amount")]),
                            _c("br"),
                            _vm._v(" "),
                            _c("h5", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatMoney")(_vm.paymentForm.amount)
                                )
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-xl-4 col-md-12 col-12" },
                          [
                            _c("span", [_vm._v("Payer Name")]),
                            _c("br"),
                            _vm._v(" "),
                            _c("h5", [_vm._v(_vm._s(_vm.paymentForm.name))])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-xl-4 col-md-12 col-12" },
                          [
                            _c("span", [_vm._v("Payer Email")]),
                            _c("br"),
                            _vm._v(" "),
                            _c("h5", [_vm._v(_vm._s(_vm.paymentForm.email))])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-xl-4 col-md-12 col-12" },
                          [
                            _c("span", [_vm._v("Payer Phone No.")]),
                            _c("br"),
                            _vm._v(" "),
                            _c("h5", [_vm._v(_vm._s(_vm.paymentForm.phone))])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-xl-4 col-md-12 col-12" },
                          [
                            _c("span", [_vm._v("MDA")]),
                            _c("br"),
                            _vm._v(" "),
                            _c("h5", [_vm._v(_vm._s(_vm.mda))])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-xl-4 col-md-12 col-12" },
                          [
                            _c("span", [_vm._v("Pay Item")]),
                            _c("br"),
                            _vm._v(" "),
                            _c("h5", [_vm._v(_vm._s(_vm.pay_item))])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-xl-12 col-md-12 col-12" },
                          [
                            _c("span", [_vm._v("Description")]),
                            _c("br"),
                            _vm._v(" "),
                            _c("h5", [_vm._v(_vm._s(_vm.description))])
                          ]
                        ),
                        _vm._v(" "),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "btn btn-outline-primary float-left btn-inline mb-50",
                          attrs: { href: "#" },
                          on: { click: _vm.previewsUrl }
                        },
                        [_vm._v("Back")]
                      ),
                      _vm._v(" "),
                      _vm.remark != "success"
                        ? _c(
                            "button-spinner",
                            {
                              staticClass:
                                "btn btn-success float-right btn-inline mb-50",
                              staticStyle: { height: "39px", color: "#fff" },
                              attrs: {
                                "is-loading": _vm.paymentForm.isMonnifyLoading,
                                disabled: _vm.paymentForm.isMonnifyLoading
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.handleClick(3)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                                    Pay With Monnify\n                                "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.remark != "success"
                        ? _c(
                            "button-spinner",
                            {
                              staticClass:
                                "btn btn-primary float-right btn-inline mb-50 mr-1",
                              staticStyle: { height: "39px", color: "#fff" },
                              attrs: {
                                "is-loading": _vm.paymentForm.isLoading,
                                disabled: _vm.paymentForm.isLoading
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.handleClick(1)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                                    Pay With Remita\n                                "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "col-lg-12 d-lg-block d-none text-center align-self-center",
        staticStyle: { padding: "40px", "margin-top": "20px" }
      },
      [
        _c("img", {
          attrs: {
            src: "/../images/pages/login.png",
            width: "332px",
            alt: "branding logo"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-12 col-12" }, [
      _c("div", { staticClass: "alert alert-warning" }, [
        _c("span", [
          _vm._v(
            "Complete your payment in seconds with the REMITA/MONNIFY option."
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }