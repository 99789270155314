var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-header row d-print-none" }, [
      _c("div", { staticClass: "content-header-left col-md-7 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(
                _vm._s(_vm.trans("payment.bill_and_payment")) +
                  "\n                    "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/home" } }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(_vm.trans("general.home")) +
                          "\n                                "
                      )
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/payment" } }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(_vm.trans("payment.bill_and_payment")) +
                          "\n                                "
                      )
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v(
                    "\n                                " +
                      _vm._s(_vm.trans("payment.print")) +
                      "\n                            "
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-5 col-12 d-md-block d-none"
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-sm pull-right ml-1",
                on: {
                  click: function($event) {
                    return _vm.$router.push("/payment/create")
                  }
                }
              },
              [
                _c("i", { staticClass: "feather icon-plus" }),
                _vm._v(" " + _vm._s(_vm.trans("payment.generate_invoice")))
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-sm pull-right ml-1",
                on: {
                  click: function($event) {
                    return _vm.$router.push("/payment")
                  }
                }
              },
              [
                _c("i", { staticClass: "feather icon-list" }),
                _vm._v(" " + _vm._s(_vm.trans("payment.bill_and_payment")))
              ]
            )
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c("section", { staticClass: "payment-preview-wrapper" }, [
        _c("div", { staticClass: "row payment-preview" }, [
          _c("div", { staticClass: "col-xl-9 col-md-8 col-12" }, [
            _c("div", { staticClass: "card payment-preview-card" }, [
              _c("div", { staticClass: "card-body payment-padding pb-0" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between flex-md-row flex-column payment-spacing mt-0"
                  },
                  [
                    _c("div", [
                      _c("div", { staticClass: "logo-wrapper" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.getMainLogo,
                            alt: "",
                            height: "24",
                            width: "35"
                          }
                        }),
                        _vm._v(" "),
                        _c("h3", { staticClass: "text-success payment-logo" }, [
                          _vm._v(_vm._s(_vm.companyName))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "card-text mb-25" }, [
                        _vm._v(
                          _vm._s(_vm.getConfig("address_line_1")) +
                            ", " +
                            _vm._s(_vm.getConfig("state")) +
                            ", " +
                            _vm._s(_vm.getConfig("country"))
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "card-text mb-0" }, [
                        _vm._v(_vm._s(_vm.getConfig("phone")))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt-md-0 mt-2" }, [
                      _c("h4", { staticClass: "payment-title" }, [
                        _vm._v(
                          "\n                                            Transaction Ref:\n                                            "
                        ),
                        _c("span", { staticClass: "payment-number" }, [
                          _vm._v("#" + _vm._s(_vm.reference))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "payment-date-wrapper" }, [
                        _c("p", { staticClass: "payment-date-title" }, [
                          _vm._v("Date Issued:")
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "payment-date" }, [
                          _vm._v(_vm._s(_vm.created_at))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "payment-date-wrapper" }, [
                        _c("p", { staticClass: "payment-date-title" }, [
                          _vm._v("Due Date:")
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "payment-date" }, [
                          _vm._v(_vm._s(_vm.created_at))
                        ])
                      ])
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("hr", { staticClass: "payment-spacing" }),
              _vm._v(" "),
              _c("div", { staticClass: "card-body payment-padding pt-0" }, [
                _c("div", { staticClass: "row payment-spacing" }, [
                  _c("div", { staticClass: "col-xl-8 p-0" }, [
                    _c("h6", { staticClass: "mb-2" }, [_vm._v("Invoice To:")]),
                    _vm._v(" "),
                    _c("h6", { staticClass: "mb-25" }, [
                      _vm._v(_vm._s(_vm.name))
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "card-text mb-25" }, [
                      _vm._v(_vm._s(_vm.address))
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "card-text mb-25" }, [
                      _vm._v(_vm._s(_vm.phone))
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "card-text mb-0" }, [
                      _vm._v(_vm._s(_vm.email))
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-xl-4 p-0 mt-xl-0 mt-2 d-print-none" },
                    [
                      _c("h6", { staticClass: "mb-2" }, [
                        _vm._v("Payment Details:")
                      ]),
                      _vm._v(" "),
                      _c("table", [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", { staticClass: "pr-1" }, [
                              _vm._v("Total Due:")
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(_vm._f("formatMoney")(_vm.due)))
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", { staticClass: "pr-1" }, [
                              _vm._v(_vm._s(_vm.trans("payment.tax")) + ":")
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(_vm.tax))])
                          ]),
                          _vm._v(" "),
                          _vm._m(0),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", { staticClass: "pr-1" }, [
                              _vm._v(
                                _vm._s(_vm.trans("payment.invoice_no")) + ":"
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(_vm.invoice_no))])
                          ])
                        ])
                      ])
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table table-responsive" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { staticClass: "py-1" }, [_vm._v("Service")]),
                      _vm._v(" "),
                      _c("th", { staticClass: "py-1" }, [
                        _vm._v(_vm._s(_vm.trans("payment.amount")))
                      ]),
                      _vm._v(" "),
                      _c("th", { staticClass: "py-1" }, [
                        _vm._v(_vm._s(_vm.trans("payment.quantity")))
                      ]),
                      _vm._v(" "),
                      _c("th", { staticClass: "py-1" }, [
                        _vm._v(_vm._s(_vm.trans("payment.total")))
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.rows, function(row, k) {
                      return _c(
                        "tr",
                        { key: k, class: k > 0 ? "border-bottom" : "" },
                        [
                          _c("td", { staticClass: "py-1" }, [
                            _c(
                              "p",
                              {
                                staticClass: "card-text font-weight-bold mb-25"
                              },
                              [_vm._v(_vm._s(row.name))]
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "py-1" }, [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v(
                                _vm._s(_vm._f("formatMoney")(row.pivot.amount))
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "py-1" }, [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v(_vm._s(row.pivot.quantity))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "py-1" }, [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v(
                                _vm._s(_vm._f("formatMoney")(row.pivot.total))
                              )
                            ])
                          ])
                        ]
                      )
                    }),
                    0
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card-body payment-padding pb-0" }, [
                _c(
                  "div",
                  { staticClass: "row payment-payments-total-wrapper" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "col-md-6 order-md-1 order-2 mt-md-0 mt-3"
                      },
                      [
                        _c("p", { staticClass: "card-text mb-0" }, [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v("Generated By:")
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml-75" }, [
                            _vm._v(_vm._s(_vm.user))
                          ])
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-6 d-flex order-md-2 order-1" },
                      [
                        _c("div", { staticClass: "payment-total-wrapper" }, [
                          _c("div", { staticClass: "payment-total-item" }, [
                            _c("p", { staticClass: "payment-total-title" }, [
                              _vm._v(
                                _vm._s(_vm.trans("payment.sub_total")) + ":"
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "payment-total-amount" }, [
                              _vm._v(
                                _vm._s(_vm._f("formatMoney")(_vm.sub_total))
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "payment-total-item" }, [
                            _c("p", { staticClass: "payment-total-title" }, [
                              _vm._v(
                                _vm._s(_vm.trans("payment.discount")) + ":"
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "payment-total-amount" }, [
                              _vm._v(
                                _vm._s(_vm._f("formatMoney")(_vm.discount))
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "payment-total-item" }, [
                            _c("p", { staticClass: "payment-total-title" }, [
                              _vm._v(_vm._s(_vm.trans("payment.tax")) + ":")
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "payment-total-amount" }, [
                              _vm._v("--")
                            ])
                          ]),
                          _vm._v(" "),
                          _c("hr", { staticClass: "my-50" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "payment-total-item" }, [
                            _c("p", { staticClass: "payment-total-title" }, [
                              _vm._v(_vm._s(_vm.trans("payment.total")) + ":")
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "payment-total-amount" }, [
                              _vm._v(_vm._s(_vm._f("formatMoney")(_vm.total)))
                            ])
                          ])
                        ])
                      ]
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("hr", { staticClass: "payment-spacing" }),
              _vm._v(" "),
              _c("div", { staticClass: "card-body payment-padding pt-0" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("Note:")
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "Thank You For Choosing " +
                          _vm._s(_vm.companyName) +
                          "!"
                      )
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-xl-3 col-md-4 col-12 payment-actions mt-md-0 mt-2 d-print-none"
            },
            [
              _c("div", { staticClass: "card" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-block mb-75",
                        attrs: {
                          "data-toggle": "modal",
                          "data-target": "#send-payment-sidebar"
                        }
                      },
                      [
                        _c("i", { staticClass: "feather icon-send" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.trans("general.send_invoice")) +
                            "\n                                "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "btn btn-success btn-block btn-download-payment mb-75",
                        attrs: { href: _vm.getDownloadLink(_vm.id) }
                      },
                      [
                        _c("i", { staticClass: "feather icon-download" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.trans("general.download")) +
                            "\n                                "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "btn btn-outline-secondary btn-block mb-75",
                        attrs: {
                          href: "/payment/print/" + _vm.id,
                          target: "_blank"
                        }
                      },
                      [
                        _c("i", { staticClass: "feather icon-printer" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.trans("general.print_invoice")) +
                            "\n                                "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-info btn-block",
                        attrs: { to: "/payment/" + _vm.id + "/edit" }
                      },
                      [
                        _c("i", { staticClass: "feather icon-edit-2" }),
                        _vm._v(" " + _vm._s(_vm.trans("payment.edit_payment")))
                      ]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { staticClass: "pr-1" }, [_vm._v("Country:")]),
      _vm._v(" "),
      _c("td", [_vm._v("Nigeria")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }