var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "row flexbox-container" }, [
    _c("div", { staticClass: "col-xl-8 col-10 justify-content-center" }, [
      _c("div", { staticClass: "card bg-authentication rounded-0 mb-0" }, [
        _c("div", { staticClass: "row m-0" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-12 col-12" }, [
            _vm.status == "not_found"
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _c("span", [_vm._v("Invoice not found.")])
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-12 col-12 p-0" }, [
            _c("div", { staticClass: "card rounded-0 mb-0 p-2" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("div", { staticClass: "card-content" }, [
                _c("div", { staticClass: "card-body pt-0" }, [
                  _c(
                    "form",
                    {
                      attrs: { id: "registerform" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.searchForInvoice.apply(null, arguments)
                        },
                        keydown: function($event) {
                          return _vm.invoiceForm.errors.clear(
                            $event.target.name
                          )
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-xl-12 col-md-12 col-12" },
                          [
                            _c(
                              "div",
                              { staticClass: "input-group w-auto" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.invoiceForm.reference,
                                      expression: "invoiceForm.reference"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid": _vm.invoiceForm.errors.has(
                                      "reference"
                                    )
                                  },
                                  attrs: {
                                    type: "text",
                                    value: "",
                                    placeholder: "Enter Reference No."
                                  },
                                  domProps: {
                                    value: _vm.invoiceForm.reference
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.invoiceForm,
                                        "reference",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("show-error", {
                                  attrs: {
                                    "form-name": _vm.invoiceForm,
                                    "prop-name": "reference"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "button-spinner",
                                  {
                                    staticClass:
                                      "btn btn-primary float-right btn-inline mb-50",
                                    staticStyle: {
                                      height: "39px",
                                      color: "#fff"
                                    },
                                    attrs: {
                                      "is-loading": _vm.invoiceForm.isLoading,
                                      disabled: _vm.invoiceForm.isLoading
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                            Search\n                                            "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ])
                    ]
                  )
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "col-lg-12 d-lg-block d-none text-center align-self-center",
        staticStyle: { padding: "40px", "margin-top": "20px" }
      },
      [
        _c("img", {
          attrs: {
            src: "/../images/pages/login.png",
            width: "332px",
            alt: "branding logo"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-headers pt-50 pb-1" }, [
      _c("div", { staticClass: "card-title" }, [
        _c("h4", { staticClass: "mb-0 text-left" }, [
          _vm._v("SEARCH FOR INVOICE")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }