var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      staticClass: "navigation navigation-main",
      attrs: { id: "main-menu-navigation", "data-menu": "menu-navigation" }
    },
    [
      _c(
        "li",
        {
          class: [
            _vm.currentPage.includes("home") ? _vm.activeClass : "",
            "nav-item"
          ]
        },
        [
          _c("router-link", { attrs: { to: "/home", exact: "" } }, [
            _c("i", { staticClass: "feather icon-home" }),
            _vm._v(" "),
            _c("span", { staticClass: "menu-title" }, [
              _vm._v(_vm._s(_vm.trans("general.home")))
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          class: [
            _vm.currentPage.includes("payment") ? _vm.activeClass : "",
            "nav-item"
          ]
        },
        [
          _c("router-link", { attrs: { to: "/payment", exact: "" } }, [
            _c("i", { staticClass: "feather icon-dollar-sign" }),
            _vm._v(" "),
            _c("span", { staticClass: "menu-title" }, [
              _vm._v("\n\t\t\t\t\tTransactions\n\t\t\t\t")
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasPermission("list-user")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("user") ? _vm.activeClass : "",
                "nav-item"
              ]
            },
            [
              _c("router-link", { attrs: { to: "/user", exact: "" } }, [
                _c("i", { staticClass: "feather icon-users" }),
                _vm._v(" "),
                _c("span", { staticClass: "menu-title" }, [_vm._v("User")])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("list-user") && _vm.getConfig("show_user_menu")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("staff") ? _vm.activeClass : "",
                "nav-item"
              ]
            },
            [
              _c("router-link", { attrs: { to: "/staff", exact: "" } }, [
                _c("i", { staticClass: "feather icon-users" }),
                _vm._v(" "),
                _c("span", { staticClass: "menu-title" }, [
                  _vm._v("\n\t\t\t\t\tStaff\n\t\t\t\t")
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-settings")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("developer") ? _vm.activeClass : "",
                "nav-item"
              ]
            },
            [
              _c("router-link", { attrs: { to: "/developer", exact: "" } }, [
                _c("i", { staticClass: "feather icon-code" }),
                _vm._v(" "),
                _c("span", { staticClass: "menu-title" }, [
                  _vm._v("\n\t\t\t\t\tDeveloper\n\t\t\t\t")
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-settings")
        ? _c("li", { staticClass: "nav-item has-sub" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("ul", { staticClass: "menu-content" }, [
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("setting/pay-item")
                      ? _vm.activeClass
                      : ""
                  ]
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/setting/pay-item", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-list" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t            \t\t\tPay Item\n\t            \t\t"
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-message") && _vm.getConfig("show_message_menu")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("message") ? _vm.activeClass : "",
                "nav-item"
              ]
            },
            [
              _c("router-link", { attrs: { to: "/message", exact: "" } }, [
                _c("i", { staticClass: "feather icon-mail" }),
                _vm._v(" "),
                _c("span", { staticClass: "menu-title" }, [
                  _vm._v(
                    "\n\t    \t\t\t" +
                      _vm._s(_vm.trans("message.message")) +
                      "\n\t    \t\t"
                  )
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-configuration") &&
      _vm.getConfig("show_permission_menu")
        ? _c("li", { staticClass: "nav-item has-sub" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("ul", { staticClass: "menu-content" }, [
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("role") ? _vm.activeClass : ""
                  ]
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/configuration/role", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-log-in" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t        \t\t\t\t" +
                            _vm._s(_vm.trans("role.role")) +
                            "\n\t        \t\t\t"
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("permission")
                      ? _vm.activeClass
                      : ""
                  ]
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/configuration/permission", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-lock" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t    \t\t\t\t\t" +
                            _vm._s(_vm.trans("permission.permission")) +
                            "\n\t    \t\t\t\t"
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-configuration") &&
      _vm.getConfig("show_activity_log_menu")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("activity-log") ? _vm.activeClass : "",
                "nav-item"
              ]
            },
            [
              _c("router-link", { attrs: { to: "/activity-log", exact: "" } }, [
                _c("i", { staticClass: "feather icon-activity" }),
                _vm._v(" "),
                _c("span", { staticClass: "menu-title" }, [
                  _vm._v(
                    "\n\t    \t\t\t" +
                      _vm._s(_vm.trans("activity.activity_log")) +
                      "\n\t    \t\t"
                  )
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-configuration") && _vm.getConfig("ip_filter")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("ip-filter") ? _vm.activeClass : "",
                "nav-item"
              ]
            },
            [
              _c(
                "router-link",
                { attrs: { to: "/configuration/ip-filter", exact: "" } },
                [
                  _c("i", { staticClass: "feather icon-filter" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "menu-title" }, [
                    _vm._v(
                      "\n\t    \t\t\t" +
                        _vm._s(_vm.trans("ip_filter.ip_filter")) +
                        "\n\t    \t\t"
                    )
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-configuration") &&
      _vm.getConfig("show_schedule_job_menu")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("scheduled-job")
                  ? _vm.activeClass
                  : "",
                "nav-item"
              ]
            },
            [
              _c(
                "router-link",
                { attrs: { to: "/configuration/scheduled-job", exact: "" } },
                [
                  _c("i", { staticClass: "feather icon-clock" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "menu-title" }, [
                    _vm._v(
                      "\n\t    \t\t\t" +
                        _vm._s(_vm.trans("general.scheduled_job")) +
                        "\n\t    \t\t"
                    )
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-configuration") &&
      _vm.getConfig("show_backup_menu")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("backup") ? _vm.activeClass : "",
                "nav-item"
              ]
            },
            [
              _c("router-link", { attrs: { to: "/backup", exact: "" } }, [
                _c("i", { staticClass: "feather icon-box" }),
                _vm._v(" "),
                _c("span", { staticClass: "menu-title" }, [
                  _vm._v(
                    "\n\t    \t\t\t" +
                      _vm._s(_vm.trans("backup.backup")) +
                      "\n\t    \t\t"
                  )
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("list-report")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("report") ? _vm.activeClass : "",
                "nav-item"
              ]
            },
            [
              _c("router-link", { attrs: { to: "/report", exact: "" } }, [
                _c("i", { staticClass: "feather icon-database" }),
                _vm._v(" "),
                _c("span", { staticClass: "menu-title" }, [
                  _vm._v("\n\t    \t\t\tReport\n\t    \t\t")
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "li",
        {
          class: [
            _vm.currentPage.includes("report") ? _vm.activeClass : "",
            "nav-item"
          ]
        },
        [_vm._m(2)]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "" } }, [
      _c("i", { staticClass: "feather icon-settings" }),
      _vm._v(" "),
      _c("span", { staticClass: "menu-title", attrs: { "data-i18n": "" } }, [
        _vm._v("Settings")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "" } }, [
      _c("i", { staticClass: "feather icon-lock" }),
      _vm._v(" "),
      _c("span", { staticClass: "menu-title", attrs: { "data-i18n": "" } }, [
        _vm._v("Permissions")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { attrs: { href: "http://developer.irs.sk.gov.ng", target: "_blank" } },
      [
        _c("i", { staticClass: "feather icon-book" }),
        _vm._v(" "),
        _c("span", { staticClass: "menu-title" }, [
          _vm._v("\n\t    \t\t\tDocumentation\n\t    \t\t")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }