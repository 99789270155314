<template>
    <section class="row flexbox-container">
  <div class="col-xl-8 col-10 justify-content-center">
      <div class="card bg-authentication rounded-0 mb-0">
          <div class="row m-0">
              <div class="col-lg-12 d-lg-block d-none text-center align-self-center" style="padding: 40px; margin-top: 20px;">
                  <img src="/../images/pages/login.png" width="332px" alt="branding logo">
              </div>
              <div class="col-lg-12 col-12">
                <div v-if="status == 'not_found'" class="alert alert-danger">
                    <span>Invoice not found.</span>
                </div>
              </div>
              <div class="col-lg-12 col-12 p-0">
                
                  <div class="card rounded-0 mb-0 p-2">
                      <div class="card-headers pt-50 pb-1">
                          <div class="card-title">
                              <h4 class="mb-0 text-left">SEARCH FOR INVOICE</h4>
                          </div>
                      </div>
                      <hr>
                      <div class="card-content">
                          <div class="card-body pt-0">
                            <form id="registerform" @submit.prevent="searchForInvoice" @keydown="invoiceForm.errors.clear($event.target.name)">
                                <div class="row">
                                    <div class="col-xl-12 col-md-12 col-12">
                                        <div class="input-group w-auto">
                                            <input class="form-control" :class="{ 'is-invalid': invoiceForm.errors.has('reference') }" type="text" value="" v-model="invoiceForm.reference" :placeholder="'Enter Reference No.'">
                                            
                                            <show-error :form-name="invoiceForm" prop-name="reference"></show-error>
                                            <button-spinner
                                                :is-loading="invoiceForm.isLoading" 
                                                :disabled="invoiceForm.isLoading"
                                                
                                                class="btn btn-primary float-right btn-inline mb-50"
                                                style="height: 39px; color: #fff;"
                                                >
                                            Search
                                            </button-spinner>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                                
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
    
</template>

<script>
    export default {
        data() {
            return {
                invoiceForm: new Form({
                    isLoading: false,
                    reference: ''
                }),
                status: ''
            }
        },
        methods: {
            searchForInvoice(){
                this.invoiceForm.isLoading = true;
                this.invoiceForm.post('/api/search-by-reference')
                    .then(response => {
                        this.invoiceForm.reference = response.reference;
                        this.invoiceForm.isLoading = false;
                        this.$router.push('/make-payment/' + response.reference);
                        
                    })
                    .catch(error => {
                        this.invoiceForm.isLoading = false;
                        this.status = 'not_found';
                    });
            },
            
            getConfig(config){
                return helper.getConfig(config);
            }
        }
    }
</script>
