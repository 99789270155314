<template>
    <section class="row flexbox-container">
  <div class="col-xl-8 col-10 justify-content-center">
      <div class="card bg-authentication rounded-0 mb-0">
          <div class="row m-0">
              <div class="col-lg-12 d-lg-block d-none text-center align-self-center" style="padding: 40px; margin-top: 20px;">
                  <img src="/../images/pages/login.png" width="332px" alt="branding logo">
              </div>
              <div class="col-lg-12 col-12">
                <div class="alert alert-warning">
                    <span>Complete your payment in seconds with the REMITA/MONNIFY option.</span>
                </div>
              </div>
              <div class="col-lg-12 col-12 p-0">
                
                  <div class="card rounded-0 mb-0 p-2">
                      <div class="card-headers pt-50 pb-1">
                          <div class="card-title">
                              <h4 class="mb-0 text-left">MAKE PAYMENT</h4>

                              <h4 v-if="remark == 'success'" class="mb-0 text-right text-success"><i class="feather icon-check"></i> INVOICE HAS BEEN PAID</h4>
                          </div>
                      </div>
                      <hr>
                      <div class="card-content">
                          <div class="card-body pt-0">
                            <form id="registerform" @submit.prevent="storePayment" @keydown="paymentForm.errors.clear($event.target.name)">
                                <div class="row">
                                    <div class="col-xl-6 col-md-12 col-12">
                                        <div class="form-group">
                                            <h2 class="mb-0">INVOICE NO: {{ paymentForm.reference }}</h2>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-md-12 col-12">
                                        <div class="form-group">
                                            <h2 class="mb-0 text-right"><i class="fa fa-building"></i> {{ mda }}</h2>
                                        </div>
                                    </div>
                               
                                    <div class="col-xl-4 col-md-12 col-12">
                                        <span>Invoice Amount</span><br>
                                        <h5>{{ paymentForm.amount | formatMoney }}</h5>
                                    </div>
                                    <div class="col-xl-4 col-md-12 col-12">
                                        <span>Payer Name</span><br>
                                        <h5>{{ paymentForm.name }}</h5>
                                    </div>
                                    <div class="col-xl-4 col-md-12 col-12">
                                        <span>Payer Email</span><br>
                                        <h5>{{ paymentForm.email }}</h5>
                                    </div>
                                    <div class="col-xl-4 col-md-12 col-12">
                                        <span>Payer Phone No.</span><br>
                                        <h5>{{ paymentForm.phone }}</h5>
                                    </div>
                                    <div class="col-xl-4 col-md-12 col-12">
                                        <span>MDA</span><br>
                                        <h5>{{ mda }}</h5>
                                    </div>
                                    <div class="col-xl-4 col-md-12 col-12">
                                        <span>Pay Item</span><br>
                                        <h5>{{ pay_item }}</h5>
                                    </div>
                                    <div class="col-xl-12 col-md-12 col-12">
                                        <span>Description</span><br>
                                        <h5>{{ description }}</h5>
                                    </div>
                               
                                    
                                    <br>
                                </div>
                                <br>
                                <a href="#" @click="previewsUrl" class="btn btn-outline-primary float-left btn-inline mb-50">Back</a>
                                <button-spinner
                                    v-if="remark != 'success'"
                                    :is-loading="paymentForm.isMonnifyLoading" 
                                    :disabled="paymentForm.isMonnifyLoading"
                                    
                                    class="btn btn-success float-right btn-inline mb-50"
                                    style="height: 39px; color: #fff;"
                                    v-on:click.native="handleClick(3)"
                                    >
                                    Pay With Monnify
                                </button-spinner>
                                  <button-spinner
                                    v-if="remark != 'success'"
                                    :is-loading="paymentForm.isLoading" 
                                    :disabled="paymentForm.isLoading"
                                    
                                    class="btn btn-primary float-right btn-inline mb-50 mr-1"
                                    style="height: 39px; color: #fff;"
                                    v-on:click.native="handleClick(1)"
                                    >
                                    Pay With Remita
                                </button-spinner>
                                
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
    
</template>

<script>
    export default {
        data() {
            return {
                paymentForm: new Form({
                    isLoading: false,
                    isMonnifyLoading: false,
                    reference: this.$route.params.reference,
                    user_id: '',
                    tax_office_id: '',
                    name: '',
                    phone: '',
                    email: '',
                    amount: '',
                    gateway_id: '',
                    description: '',
                    pay_items: [{
                        mda_id: '',
                        pay_item_id: '',
                        amount: '',
                        description: ''
                    }]
                }),
                mda: '',
                pay_item: '',
                tax_id: '',
                rrr: '',
                remark: '',
                description: ''
            }
        },
        mounted(){
            this.getPayment();
        },
        methods: {
            getPayment(){
                axios.get('/api/make-payment/'+this.paymentForm.reference)
                    .then(response => response.data)
                    .then(response => {
                        this.paymentForm.user_id = response.user_id;
                        this.paymentForm.reference = response.reference;
                        this.paymentForm.name = response.payer_name;
                        this.paymentForm.phone = response.payer_phone;
                        this.paymentForm.email = response.payer_email;
                        this.paymentForm.amount = response.amount;
                        this.paymentForm.pay_items = response.payment_items;
                        this.paymentForm.gateway_id = response.gateway_id;
                        this.paymentForm.tax_office_id = response.tax_office_id;
                        this.mda = response.payment_items[0].mda.name;
                        this.pay_item = response.payment_items[0].pay_item.name;
                        this.remark = response.remark;
                        this.description = response.description;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                        this.$router.push('/search-by-reference');
                    });
            },
            storePayment(){
                if (this.paymentForm.gateway_id == 1) {
                    this.paymentForm.isLoading = true;
                } else if (this.paymentForm.gateway_id == 3) {
                    this.paymentForm.isMonnifyLoading = true;
                }
                
                this.paymentForm.post('/api/payment')
                    .then(response => {
                        this.paymentForm.reference = response.reference;
                        this.tax_id = response.user.tax_id;
                        this.paymentForm.phone = response.user.phone;
                        this.paymentForm.email = response.user.email;
                        this.paymentForm.pay_items = response.payment_items;
                        this.paymentForm.amount = response.amount;
                        this.paymentForm.gateway_id = response.gateway_id;
                        this.rrr = response.rrr;
                        if (this.paymentForm.gateway_id == 1) {
                            this.paymentForm.isLoading = false;
                            this.makeRemitaPayment(this.rrr, this.getPayment());
                        } else if (this.paymentForm.gateway_id == 3) {
                            this.paymentForm.isMonnifyLoading = false;
                            window.location.href = 'https://sandbox.sdk.monnify.com/checkout/' + response.rrr;
                        }
                    })
                    .catch(error => {
                        if (this.paymentForm.gateway_id == 1) {
                            this.paymentForm.isLoading = false;
                        } else if (this.paymentForm.gateway_id == 3) {
                            this.paymentForm.isMonnifyLoading = false;
                        }
                        toastr.error(error.message);
                        helper.showErrorMsg(error);
                    });
            },
            
            getConfig(config){
                return helper.getConfig(config);
            },
            makeRemitaPayment(rrr, getPayment) {
                var randomnumber = Math.floor(Math.random());
                var paymentEngine = RmPaymentEngine.init({
                    key: "QzAwMDAyNzEyNTl8MTEwNjE4NjF8OWZjOWYwNmMyZDk3MDRhYWM3YThiOThlNTNjZTE3ZjYxOTY5NDdmZWE1YzU3NDc0ZjE2ZDZjNTg1YWYxNWY3NWM4ZjMzNzZhNjNhZWZlOWQwNmJhNTFkMjIxYTRiMjYzZDkzNGQ3NTUxNDIxYWNlOGY4ZWEyODY3ZjlhNGUwYTY",
                    merchantName: "Sokoto Internal Revenue Services",
                    processRrr: true,
                    transactionId: randomnumber,
                    extendedData: { 
                        customFields: [ 
                            { 
                                name: "rrr", 
                                value: rrr
                            } 
                        ]
                    },
                    
                    onSuccess: function (response) {
                        axios.get('/api/payment/verify/?rrr=' + rrr)
                            .then(response => response.data)
                            .then(response => {
                                if (response.data.message == "Successful") {
                                    toastr.success('Payment successful.');
                                    //this.$router.push('/payment/' + response.data.orderId);
                                    window.location.href = '/payment/' + response.data.orderId + '/print';
                                } else {
                                    toastr.error('There is an error.');
                                }
                            })
                            .catch(error => {
                                helper.showDataErrorMsg(error)
                            });
                    },
                    onError: function (response) {
                        toastr.error(response);
                        console.log('callback Error Response', response);
                    },
                    onClose: function () {
                        getPayment;
                        console.log("closed");
                    }
                });
                    paymentEngine.showPaymentWidget();
            },

            previewsUrl(){
                return window.history.back();
            },

            handleClick(index){
                console.log('index is' + index);
                this.paymentForm.gateway_id = index;
            },
        },
        filters: {
          formatMoney(value){
            return helper.formatMoney(value);
          }
        },
    }
</script>
