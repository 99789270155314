<template>
    <div>
        <div class="content-header row d-print-none">
            <div class="content-header-left col-md-7 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('payment.bill_and_payment')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/home">
                                        {{ trans('general.home') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item">
                                    <router-link to="/payment">
                                        {{ trans('payment.bill_and_payment') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item active">
                                    {{ trans('payment.print') }}
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-5 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right ml-1" @click="$router.push('/payment/create')"><i class="feather icon-plus"></i> {{trans('payment.generate_invoice')}}</button>

                    <button class="btn btn-primary btn-sm pull-right ml-1" @click="$router.push('/payment')"><i class="feather icon-list"></i> {{trans('payment.bill_and_payment')}}</button>
                    
                </div>
            </div>

        </div>

        <div class="content-body">
            <section class="payment-preview-wrapper">
                    <div class="row payment-preview">
                        <!-- Invoice -->
                        <div class="col-xl-9 col-md-8 col-12">
                            <div class="card payment-preview-card">
                                <div class="card-body payment-padding pb-0">
                                    <!-- Header starts -->
                                    <div class="d-flex justify-content-between flex-md-row flex-column payment-spacing mt-0">
                                        <div>
                                            <div class="logo-wrapper">
                                                <img :src="getMainLogo" alt="" class="" height="24" width="35" />
                                                <h3 class="text-success payment-logo">{{ companyName }}</h3>
                                            </div>
                                            <p class="card-text mb-25">{{ getConfig('address_line_1') }}, {{ getConfig('state') }}, {{ getConfig('country') }}</p>
                                            <p class="card-text mb-0">{{ getConfig('phone') }}</p>
                                        </div>
                                        <div class="mt-md-0 mt-2">
                                            <h4 class="payment-title">
                                                Transaction Ref:
                                                <span class="payment-number">#{{ reference }}</span>
                                            </h4>
                                            <div class="payment-date-wrapper">
                                                <p class="payment-date-title">Date Issued:</p>
                                                <p class="payment-date">{{ created_at }}</p>
                                            </div>
                                            <div class="payment-date-wrapper">
                                                <p class="payment-date-title">Due Date:</p>
                                                <p class="payment-date">{{ created_at }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Header ends -->
                                </div>

                                <hr class="payment-spacing" />

                                <!-- Address and Contact starts -->
                                <div class="card-body payment-padding pt-0">
                                    <div class="row payment-spacing">
                                        <div class="col-xl-8 p-0">
                                            <h6 class="mb-2">Invoice To:</h6>
                                            <h6 class="mb-25">{{ name }}</h6>
                                            <p class="card-text mb-25">{{ address }}</p>
                                            <p class="card-text mb-25">{{ phone }}</p>
                                            <p class="card-text mb-0">{{ email }}</p>
                                        </div>
                                        <div class="col-xl-4 p-0 mt-xl-0 mt-2 d-print-none">
                                            <h6 class="mb-2">Payment Details:</h6>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td class="pr-1">Total Due:</td>
                                                        <td><span class="font-weight-bold">{{ due | formatMoney }}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="pr-1">{{ trans('payment.tax') }}:</td>
                                                        <td>{{ tax }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="pr-1">Country:</td>
                                                        <td>Nigeria</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="pr-1">{{ trans('payment.invoice_no') }}:</td>
                                                        <td>{{ invoice_no }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <!-- Address and Contact ends -->

                                <!-- Invoice Description starts -->
                                <div class="table-responsive">
                                    <table class="table table-responsive">
                                        <thead>
                                            <tr>
                                                <th class="py-1">Service</th>
                                                <th class="py-1">{{ trans('payment.amount') }}</th>
                                                <th class="py-1">{{ trans('payment.quantity') }}</th>
                                                <th class="py-1">{{ trans('payment.total') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(row, k) in rows" :key="k" :class="k > 0 ? 'border-bottom' : ''">
                                                <td class="py-1">
                                                    <p class="card-text font-weight-bold mb-25">{{ row.name }}</p>
                                                </td>
                                                <td class="py-1">
                                                    <span class="font-weight-bold">{{ row.pivot.amount | formatMoney }}</span>
                                                </td>
                                                <td class="py-1">
                                                    <span class="font-weight-bold">{{ row.pivot.quantity }}</span>
                                                </td>
                                                <td class="py-1">
                                                    <span class="font-weight-bold">{{ row.pivot.total | formatMoney }}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="card-body payment-padding pb-0">
                                    <div class="row payment-payments-total-wrapper">
                                        <div class="col-md-6 order-md-1 order-2 mt-md-0 mt-3">
                                            <p class="card-text mb-0">
                                                <span class="font-weight-bold">Generated By:</span> <span class="ml-75">{{ user }}</span>
                                            </p>
                                        </div>
                                        <div class="col-md-6 d-flex  order-md-2 order-1">
                                            <div class="payment-total-wrapper">
                                                <div class="payment-total-item">
                                                    <p class="payment-total-title">{{ trans('payment.sub_total') }}:</p>
                                                    <p class="payment-total-amount">{{ sub_total | formatMoney }}</p>
                                                </div>
                                                <div class="payment-total-item">
                                                    <p class="payment-total-title">{{ trans('payment.discount') }}:</p>
                                                    <p class="payment-total-amount">{{ discount | formatMoney }}</p>
                                                </div>
                                                <div class="payment-total-item">
                                                    <p class="payment-total-title">{{ trans('payment.tax') }}:</p>
                                                    <p class="payment-total-amount">--</p>
                                                </div>
                                                <hr class="my-50" />
                                                <div class="payment-total-item">
                                                    <p class="payment-total-title">{{ trans('payment.total') }}:</p>
                                                    <p class="payment-total-amount">{{ total | formatMoney }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Invoice Description ends -->

                                <hr class="payment-spacing" />

                                <!-- Invoice Note starts -->
                                <div class="card-body payment-padding pt-0">
                                    <div class="row">
                                        <div class="col-12">
                                            <span class="font-weight-bold">Note:</span>
                                            <span>Thank You For Choosing {{ companyName }}!</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- Invoice Note ends -->
                            </div>
                        </div>
                        <!-- /Invoice -->

                        <!-- Invoice Actions -->
                        <div class="col-xl-3 col-md-4 col-12 payment-actions mt-md-0 mt-2 d-print-none">
                            <div class="card">
                                <div class="card-body">
                                    <button class="btn btn-primary btn-block mb-75" data-toggle="modal" data-target="#send-payment-sidebar">
                                        <i class="feather icon-send"></i> {{ trans('general.send_invoice') }}
                                    </button>

                                    <a class="btn btn-success btn-block btn-download-payment mb-75" :href="getDownloadLink(id)">
                                        <i class="feather icon-download"></i> {{ trans('general.download') }}
                                    </a>
                                    
                                    <a class="btn btn-outline-secondary btn-block mb-75" :href="'/payment/print/' + id" target="_blank">
                                        <i class="feather icon-printer"></i> {{ trans('general.print_invoice') }}
                                    </a>
                                    <router-link :to="`/payment/${id}/edit`" class="btn btn-info btn-block"><i class="feather icon-edit-2"></i> {{ trans('payment.edit_payment') }}</router-link>
    
                                </div>
                            </div>
                        </div>
                        <!-- /Invoice Actions -->
                    </div>
                </section>
        </div>
    </div>
</template>

<script>
    export default {
       data() {
            return {
                id:this.$route.params.id,
                invoice_no: '',
                name: '',
                email: '',
                phone: '',
                date: '',
                address: '',
                sub_total: 0,
                discount: 0,
                total: 0,
                paid: 0,
                due: 0,
                tax: '',
                user: '',
                rows: [{
                    name: '',
                    pivot: [{
                        quantity: '',
                        amount: '',
                        total: ''
                    }]   
                }]
                
            }
        },
        mounted(){
            if(!helper.hasPermission('list-payment')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            if(!helper.featureAvailable('payment')){
                helper.featureNotAvailableMsg();
                this.$router.push('/home');
            }

            this.getBill();
        },

        methods: {
            getBill(){
                axios.get('/api/payment/'+this.id)
                    .then(response => response.data)
                    .then(response => {
                        this.invoice_no = response.invoice_no;
                        this.name = response.patient.user.name;
                        this.phone = response.patient.user.profile.phone;
                        this.email = response.patient.user.email;
                        this.date = response.created_at;
                        this.sub_total = response.sub_total;
                        this.discount = response.discount;
                        this.total = response.grand_total;
                        this.paid = response.paid_amount;
                        this.due = response.due;
                        this.tax = response.tax;
                        this.user = response.user.name;
                        console.log(response);
                        this.rows = response.services.map(services => ( services));
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                        this.$router.push('/payment');
                    });
            },

            getConfig(config){
                return helper.getConfig(config);
            },

            getDownloadLink(id){
                return '/payment/'+id+'/download/?token='+localStorage.getItem('auth_token');
            },
        },

        computed: {
            getMainLogo(){
                if(helper.getConfig('main_logo'))
                    return '/'+helper.getConfig('main_logo');
                else
                    return '/images/logo/logo-success.png';
            },
            companyName() {
                //return this.getConfig('company_name').replace(/ .*/, '');
                return this.getConfig('company_name');
            }
        },

        filters: {
          formatMoney(value) {
            return '₦' + helper.formatMoney(value);
          },
          momentDateTime(date) {
            return helper.formatDateTime(date);
          }
        },
    }
</script>

<style>
    .payment-preview .payment-padding,
.payment-edit .payment-padding,
.payment-add .payment-padding {
  padding-left: 2.5rem;
  padding-right: 2.5rem; }

.payment-preview .table th:first-child,
.payment-preview .table td:first-child,
.payment-edit .table th:first-child,
.payment-edit .table td:first-child,
.payment-add .table th:first-child,
.payment-add .table td:first-child {
  padding-left: 2.5rem; }

.payment-preview .logo-wrapper,
.payment-edit .logo-wrapper,
.payment-add .logo-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1.9rem; }

.payment-preview .logo-wrapper .payment-logo,
.payment-edit .logo-wrapper .payment-logo,
.payment-add .logo-wrapper .payment-logo {
  font-size: 2.142rem;
  font-weight: bold;
  letter-spacing: -0.54px;
  margin-left: 1rem;
  margin-bottom: 0; }

.payment-preview .payment-title,
.payment-edit .payment-title,
.payment-add .payment-title {
  font-size: 1.285rem;
  margin-bottom: 1rem; }

.payment-preview .payment-title .payment-number,
.payment-edit .payment-title .payment-number,
.payment-add .payment-title .payment-number {
  font-weight: 600; }

.payment-preview .payment-date-wrapper,
.payment-edit .payment-date-wrapper,
.payment-add .payment-date-wrapper {
  display: flex;
  align-items: center; }

.payment-preview .payment-date-wrapper:not(:last-of-type),
.payment-edit .payment-date-wrapper:not(:last-of-type),
.payment-add .payment-date-wrapper:not(:last-of-type) {
  margin-bottom: 0.5rem; }

.payment-preview .payment-date-wrapper .payment-date-title,
.payment-edit .payment-date-wrapper .payment-date-title,
.payment-add .payment-date-wrapper .payment-date-title {
  width: 7rem;
  margin-bottom: 0; }

.payment-preview .payment-date-wrapper .payment-date,
.payment-edit .payment-date-wrapper .payment-date,
.payment-add .payment-date-wrapper .payment-date {
  margin-left: 0.5rem;
  font-weight: 600;
  margin-bottom: 0; }

.payment-preview .payment-spacing,
.payment-edit .payment-spacing,
.payment-add .payment-spacing {
  margin: 1.45rem 0; }

.payment-preview .payment-number-date .title,
.payment-edit .payment-number-date .title,
.payment-add .payment-number-date .title {
  width: 115px; }

.payment-preview .payment-total-wrapper,
.payment-edit .payment-total-wrapper,
.payment-add .payment-total-wrapper {
  width: 100%;
  max-width: 12rem; }

.payment-preview .payment-total-wrapper .payment-total-item,
.payment-edit .payment-total-wrapper .payment-total-item,
.payment-add .payment-total-wrapper .payment-total-item {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.payment-preview .payment-total-wrapper .payment-total-item .payment-total-title,
.payment-edit .payment-total-wrapper .payment-total-item .payment-total-title,
.payment-add .payment-total-wrapper .payment-total-item .payment-total-title {
  margin-bottom: 0.35rem; }

.payment-preview .payment-total-wrapper .payment-total-item .payment-total-amount,
.payment-edit .payment-total-wrapper .payment-total-item .payment-total-amount,
.payment-add .payment-total-wrapper .payment-total-item .payment-total-amount {
  margin-bottom: 0.35rem;
  font-weight: 600; }

@media (min-width: 768px) {
  .payment-preview .payment-title,
  .payment-edit .payment-title,
  .payment-add .payment-title {
    text-align: right;
    margin-bottom: 3rem; } }

.payment-edit .payment-preview-card .payment-title,
.payment-add .payment-preview-card .payment-title {
  text-align: left;
  margin-right: 3.5rem;
  margin-bottom: 0; }

.payment-edit .payment-preview-card .payment-edit-input,
.payment-edit .payment-preview-card .payment-edit-input-group,
.payment-add .payment-preview-card .payment-edit-input,
.payment-add .payment-preview-card .payment-edit-input-group {
  max-width: 11.21rem; }

.payment-edit .payment-preview-card .payment-product-details,
.payment-add .payment-preview-card .payment-product-details {
  background-color: #fcfcfc;
  padding: 3.75rem 3.45rem 2.3rem 3.45rem; }

.payment-edit .payment-preview-card .payment-product-details .product-details-border,
.payment-add .payment-preview-card .payment-product-details .product-details-border {
  border: 1px solid #ebe9f1;
  border-radius: 0.357rem; }

.payment-edit .payment-preview-card .payment-to-title,
.payment-add .payment-preview-card .payment-to-title {
  margin-bottom: 1.9rem; }

.payment-edit .payment-preview-card .col-title,
.payment-add .payment-preview-card .col-title {
  position: absolute;
  top: -3.2rem; }

.payment-edit .payment-preview-card .item-options-menu,
.payment-add .payment-preview-card .item-options-menu {
  min-width: 20rem; }

.payment-edit .payment-preview-card .repeater-wrapper:not(:last-child),
.payment-add .payment-preview-card .repeater-wrapper:not(:last-child) {
  margin-bottom: 3rem; }

.payment-edit .payment-preview-card .payment-calculations .total-amt-title,
.payment-add .payment-preview-card .payment-calculations .total-amt-title {
  width: 100px; }

@media (max-width: 769px) {
  .payment-edit .payment-preview-card .payment-title,
  .payment-add .payment-preview-card .payment-title {
    margin-right: 0;
    width: 115px; }
  .payment-edit .payment-preview-card .payment-edit-input,
  .payment-add .payment-preview-card .payment-edit-input {
    max-width: 100%; } }

@media (max-width: 992px) {
  .payment-edit .col-title,
  .payment-add .col-title {
    top: -1.5rem !important; } }

@media print {
  .payment-edit hr,
  .payment-add hr {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
    }
</style>