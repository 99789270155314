<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('user.user')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a href="/">User</a>
                                </li>
                                <li class="breadcrumb-item active" v-if="user.profile">{{user.profile.first_name+' '+user.profile.last_name}} ({{user.email}})</li>
                                </ol> 
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right" @click="$router.push('/user')"><i class="feather icon-list"></i> {{trans('user.user_list')}}</button>
                </div>
            </div>
        </div>

    <div class="content-body">
        <div id="user-profile">
            <user-sidebar menu="basic" :id="id"></user-sidebar>
  
  <section id="profile-info">
    <div class="row">
        <user-summary :user="user"></user-summary>
      
      <div class="col-lg-8 col-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">{{trans('user.basic')}}</h4>
            </div>
          <div class="card-content">
              <div class="card-body">
            <div class="d-flex justify-content-start align-items-center mb-1">

                                    <form @submit.prevent="submit" @keydown="userForm.errors.clear($event.target.name)">
                                        <div class="row">
                                            <div class="col-12 col-sm-12">
                                                <div class="row">
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('user.first_name')}}</label>
                                                            <input class="form-control" :class="{ 'is-invalid': userForm.errors.has('first_name') }" type="text" value="" v-model="userForm.first_name" name="first_name" :placeholder="trans('user.first_name')">
                                                            <show-error :form-name="userForm" prop-name="first_name"></show-error>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('user.last_name')}}</label>
                                                            <input class="form-control" :class="{ 'is-invalid': userForm.errors.has('last_name') }" type="text" value="" v-model="userForm.last_name" name="last_name" :placeholder="trans('user.last_name')">
                                                            <show-error :form-name="userForm" prop-name="last_name"></show-error>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('user.email')}}</label>
                                                            <input class="form-control" :class="{ 'is-invalid': userForm.errors.has('email') }" type="text" value="" v-model="userForm.email" name="email" :placeholder="trans('user.email')" readonly>
                                                            <show-error :form-name="userForm" prop-name="email"></show-error>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('user.gender')}}</label>
                                                            <div class="radio radio-info" v-for="gender in genders">
                                                                <input type="radio" :value="gender.id" :id="gender.id" v-model="userForm.gender" :checked="userForm.gender == gender.id" name="gender" :class="{ 'is-invalid': userForm.errors.has('gender') }">
                                                                <label :for="gender.id"> {{trans('list.'+gender.id)}} </label>
                                                            </div>
                                                            <show-error :form-name="userForm" prop-name="gender"></show-error>
                                                        </div>
                                                    </div>
                                                    <template v-if="!userHasRole(user,'admin')">
                                                        <div class="col-12 col-sm-6">
                                                            <div class="form-group">
                                                                <label for="">{{trans('role.role')}}</label>
                                                                <v-select label="name" track-by="id" v-model="selected_roles" name="role_id" id="role_id" :options="roles" :placeholder="trans('role.select_role')" @select="onRoleSelect" :multiple="true" :close-on-select="false" :clear-on-select="false" :hide-selected="true" @remove="onRoleRemove" :selected="selected_roles" :class="{ 'is-invalid': userForm.errors.has('role_id') }">
                                                                </v-select>
                                                                <show-error :form-name="userForm" prop-name="role_id"></show-error>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group" >
                                                            <label for="">{{trans('user.date_of_birth')}}</label>
                                                            <datepicker v-model="userForm.date_of_birth" :bootstrapStyling="true" name="date_of_birth" @selected="userForm.errors.clear('date_of_birth')" :class="{ 'is-invalid': userForm.errors.has('date_of_birth') }"></datepicker>
                                                            <show-error :form-name="userForm" prop-name="date_of_birth"></show-error>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('user.date_of_anniversary')}}</label>
                                                            <datepicker v-model="userForm.date_of_anniversary" :bootstrapStyling="true" name="date_of_anniversary" @selected="userForm.errors.clear('date_of_anniversary')" :class="{ 'is-invalid': userForm.errors.has('date_of_anniversary') }"></datepicker>
                                                            <show-error :form-name="userForm" prop-name="date_of_anniversary"></show-error>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('user.code')}}</label>
                                                            <input class="form-control" :class="{ 'is-invalid': userForm.errors.has('code') }" type="text" value="" v-model="userForm.code" name="code" :placeholder="trans('user.code')">
                                                            <show-error :form-name="userForm" prop-name="code"></show-error>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" class="btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right">{{trans('general.save')}}</button>
                                    </form>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
   
   </div>
    </div>
</template>

<script>
    import userSidebar from './user-sidebar'
    import datepicker from 'vuejs-datepicker'
    import vSelect from 'vue-multiselect'
    import userSummary from './summary'

    export default {
        components : { userSidebar,datepicker,vSelect,userSummary },
        data() {
            return {
                id:this.$route.params.id,
                userForm: new Form({
                    profile_id: '',
                    first_name: '',
                    last_name: '',
                    email: '',
                    gender: '',
                    date_of_birth: '',
                    date_of_anniversary: '',
                    code: '',
                    role_id: []
                },false),
                user: '',
                genders: [],
                roles: [],
                selected_roles: ''
            };
        },
        mounted(){
            if(!helper.hasPermission('edit-user')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            this.fetchUserData();
            this.fetchPreRequisites();
        },
        methods: {
            fetchUserData(){
                axios.get('/api/user/'+this.id)
                    .then(response => response.data)
                    .then(response => {
                        this.user = response.user;
                        this.userForm.profile_id = response.user.profile.id;
                        this.userForm.first_name = response.user.profile.first_name;
                        this.userForm.last_name = response.user.profile.last_name;
                        this.userForm.email = response.user.email;
                        this.userForm.gender = response.user.profile.gender;
                        this.userForm.date_of_birth = response.user.profile.date_of_birth;
                        this.userForm.date_of_anniversary = response.user.profile.date_of_anniversary;
                        this.userForm.code = response.user.profile.code;
                        this.userForm.role_id = response.roles;
                        this.selected_roles = response.selected_roles;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                        this.$router.push('/user');
                    })
            },
            fetchPreRequisites(){
                axios.get('/api/user/pre-requisite')
                    .then(response => response.data)
                    .then(response => {
                        this.countries = response.countries;
                        this.genders = response.genders;
                        this.roles = response.roles;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error)
                    });
            },
            onRoleSelect(selectedOption){
                this.userForm.errors.clear('role_id');
                this.userForm.role_id.push(selectedOption.id);
            },
            onRoleRemove(removedOption){
                this.userForm.role_id.splice(this.userForm.role_id.indexOf(removedOption.id), 1);
            },
            submit(){
                this.userForm.date_of_birth = (this.userForm.date_of_birth) ? moment(this.userForm.date_of_birth).format('YYYY-MM-DD') : null;
                this.userForm.date_of_anniversary = (this.userForm.date_of_anniversary) ? moment(this.userForm.date_of_anniversary).format('YYYY-MM-DD') : null;
                this.userForm.patch('/api/user/'+this.id)
                    .then(response => {
                        this.fetchUserData();
                        toastr.success(response.message);
                    })
                    .catch(error => {
                        helper.showErrorMsg(error);
                    });
            },
            userHasRole(user,role){
                return helper.userHasRole(user,role);
            }
        }
    }
</script>
