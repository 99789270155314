var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.proceed.apply(null, arguments)
        },
        keydown: function($event) {
          return _vm.developerForm.errors.clear($event.target.name)
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-sm-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Gateway")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.developerForm.gateway_id,
                      expression: "developerForm.gateway_id"
                    }
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid": _vm.developerForm.errors.has("gateway_id")
                  },
                  attrs: { name: "gateway_id" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.developerForm,
                        "gateway_id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("Gateway")]),
                  _vm._v(" "),
                  _vm._l(_vm.gateways, function(gateway) {
                    return _c("option", { domProps: { value: gateway.id } }, [
                      _vm._v(_vm._s(gateway.name))
                    ])
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.developerForm,
                  "prop-name": "gateway_id"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("School")]),
              _vm._v(" "),
              _c("v-select", {
                class: {
                  "form-control is-invalid": _vm.developerForm.errors.has(
                    "user_id"
                  )
                },
                attrs: {
                  options: _vm.users,
                  reduce: function(name) {
                    return name.id
                  },
                  label: "name",
                  name: "user_id"
                },
                model: {
                  value: _vm.developerForm.user_id,
                  callback: function($$v) {
                    _vm.$set(_vm.developerForm, "user_id", $$v)
                  },
                  expression: "developerForm.user_id"
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.developerForm,
                  "prop-name": "user_id"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("API Key")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.developerForm.api_key,
                    expression: "developerForm.api_key"
                  }
                ],
                staticClass: "form-control",
                class: {
                  "is-invalid": _vm.developerForm.errors.has("api_key")
                },
                attrs: {
                  type: "text",
                  value: "",
                  name: "api_key",
                  placeholder: "API Key"
                },
                domProps: { value: _vm.developerForm.api_key },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.developerForm, "api_key", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.developerForm,
                  "prop-name": "api_key"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Public Key")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.developerForm.public_key,
                    expression: "developerForm.public_key"
                  }
                ],
                staticClass: "form-control",
                class: {
                  "is-invalid": _vm.developerForm.errors.has("public_key")
                },
                attrs: {
                  type: "text",
                  value: "",
                  name: "public_key",
                  placeholder: "Public Key"
                },
                domProps: { value: _vm.developerForm.public_key },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.developerForm,
                      "public_key",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.developerForm,
                  "prop-name": "public_key"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Secret Key")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.developerForm.secret_key,
                    expression: "developerForm.secret_key"
                  }
                ],
                staticClass: "form-control",
                class: {
                  "is-invalid": _vm.developerForm.errors.has("secret_key")
                },
                attrs: {
                  type: "text",
                  value: "",
                  name: "secret_key",
                  placeholder: "Secret Key"
                },
                domProps: { value: _vm.developerForm.secret_key },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.developerForm,
                      "secret_key",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.developerForm,
                  "prop-name": "secret_key"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Merchant ID")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.developerForm.merchant_id,
                    expression: "developerForm.merchant_id"
                  }
                ],
                staticClass: "form-control",
                class: {
                  "is-invalid": _vm.developerForm.errors.has("merchant_id")
                },
                attrs: {
                  type: "text",
                  value: "",
                  name: "merchant_id",
                  placeholder: "Merchant ID"
                },
                domProps: { value: _vm.developerForm.merchant_id },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.developerForm,
                      "merchant_id",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.developerForm,
                  "prop-name": "merchant_id"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Fallback URL")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.developerForm.fallback_url,
                    expression: "developerForm.fallback_url"
                  }
                ],
                staticClass: "form-control",
                class: {
                  "is-invalid": _vm.developerForm.errors.has("fallback_url")
                },
                attrs: {
                  type: "text",
                  value: "",
                  name: "fallback_url",
                  placeholder: "Fallback URL"
                },
                domProps: { value: _vm.developerForm.fallback_url },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.developerForm,
                      "fallback_url",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.developerForm,
                  "prop-name": "fallback_url"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Response URL")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.developerForm.response_url,
                    expression: "developerForm.response_url"
                  }
                ],
                staticClass: "form-control",
                class: {
                  "is-invalid": _vm.developerForm.errors.has("response_url")
                },
                attrs: {
                  type: "text",
                  value: "",
                  name: "response_url",
                  placeholder: "Response URL"
                },
                domProps: { value: _vm.developerForm.response_url },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.developerForm,
                      "response_url",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.developerForm,
                  "prop-name": "response_url"
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass:
            "btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right",
          attrs: { type: "submit" }
        },
        [
          _vm.id
            ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
            : _c("span", [_vm._v(_vm._s(_vm.trans("general.save")))])
        ]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          directives: [
            { name: "show", rawName: "v-show", value: _vm.id, expression: "id" }
          ],
          staticClass:
            "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
          attrs: { to: "/developer" }
        },
        [_vm._v(_vm._s(_vm.trans("general.cancel")))]
      ),
      _vm._v(" "),
      !_vm.id
        ? _c(
            "button",
            {
              staticClass:
                "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.$emit("cancel")
                }
              }
            },
            [_vm._v(_vm._s(_vm.trans("general.cancel")))]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }