<template>
	<ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
	    <li :class="[currentPage.includes('home') ? activeClass : '', 'nav-item']">
	    	<router-link to="/home" exact><i class="feather icon-home"></i> <span class="menu-title">{{trans('general.home')}}</span>
	    	</router-link>
	    </li>

		<li :class="[currentPage.includes('payment') ? activeClass : '', 'nav-item']">
			<router-link to="/payment" exact>
				<i class="feather icon-dollar-sign"></i> 
				<span class="menu-title">
					Transactions
				</span>
			</router-link>
		</li>

		<li :class="[currentPage.includes('user') ? activeClass : '', 'nav-item']" v-if="hasPermission('list-user')">
	    	<router-link to="/user" exact><i class="feather icon-users"></i> <span class="menu-title">User</span>
	    	</router-link>
	    </li>

		<li :class="[currentPage.includes('staff') ? activeClass : '', 'nav-item']" v-if="hasPermission('list-user') && getConfig('show_user_menu')">
			<router-link to="/staff" exact>
				<i class="feather icon-users"></i> 
				<span class="menu-title">
					Staff
				</span>
			</router-link>
		</li>

		<li :class="[currentPage.includes('developer') ? activeClass : '', 'nav-item']" v-if="hasPermission('access-settings')">
			<router-link to="/developer" exact>
				<i class="feather icon-code"></i> 
				<span class="menu-title">
					Developer
				</span>
			</router-link>
		</li>

	    <li class="nav-item has-sub" v-if="hasPermission('access-settings')">
            <a href="">
                <i class="feather icon-settings"></i>
                <span class="menu-title" data-i18n="">Settings</span>
            </a>
            <ul class="menu-content" style="">

	    		<!-- <li :class="[currentPage.includes('mda') ? activeClass : '']">
	    			<router-link to="/setting/mda" exact>
	    				<i class="feather icon-list"></i>
	    				<span class="menu-title">
	    					MDA
	    				</span>
	    			</router-link>
	    		</li> -->
				<!-- <li :class="[currentPage.includes('gateway') ? activeClass : '']">
	    			<router-link to="/setting/gateway" exact>
	    				<i class="feather icon-list"></i>
	    				<span class="menu-title">
	    					Gateway
	    				</span>
	    			</router-link>
	    		</li> -->

	    		<li :class="[currentPage.includes('setting/pay-item') ? activeClass : '']">
	            	<router-link to="/setting/pay-item" exact>
	            		<i class="feather icon-list"></i>
	            		<span class="menu-title">
	            			Pay Item
	            		</span>
	            	</router-link>
	    		</li>

	    		<!-- <li :class="[currentPage.includes('sector') ? activeClass : '']">
	    			<router-link to="/setting/sector" exact>
	    				<i class="feather icon-list"></i>
	    				<span class="menu-title">
	    					Sector
	    				</span>
	    			</router-link>
	    		</li> -->
		    </ul>                                            
		</li>

	    <li :class="[currentPage.includes('message') ? activeClass : '', 'nav-item']" v-if="hasPermission('access-message') && getConfig('show_message_menu')">
	    	<router-link to="/message" exact>
	    		<i class="feather icon-mail"></i> 
	    		<span class="menu-title">
	    			{{trans('message.message')}}
	    		</span>
	    	</router-link>
	    </li>

		<li class="nav-item has-sub" v-if="hasPermission('access-configuration') && getConfig('show_permission_menu')">
            <a href="">
                <i class="feather icon-lock"></i>
                <span class="menu-title" data-i18n="">Permissions</span>
            </a>
            <ul class="menu-content" style="">
	        	<li :class="[currentPage.includes('role') ? activeClass : '']">
	        		<router-link to="/configuration/role" exact>
	        			<i class="feather icon-log-in"></i> 
	        			<span class="menu-title">
	        				{{trans('role.role')}}
	        			</span>
	        		</router-link>
	        	</li>
	    		<li :class="[currentPage.includes('permission') ? activeClass : '']">
	    			<router-link to="/configuration/permission" exact>
	    				<i class="feather icon-lock"></i> 
	    				<span class="menu-title">
	    					{{trans('permission.permission')}}
	    				</span>
	    			</router-link>
	    		</li>
	            
		    </ul>                                            
		</li>
	    
	    <li :class="[currentPage.includes('activity-log') ? activeClass : '', 'nav-item']" v-if="hasPermission('access-configuration') && getConfig('show_activity_log_menu')">
	    	<router-link to="/activity-log" exact>
	    		<i class="feather icon-activity"></i> 
	    		<span class="menu-title">
	    			{{trans('activity.activity_log')}}
	    		</span>
	    	</router-link>
	    </li>
	    <li :class="[currentPage.includes('ip-filter') ? activeClass : '', 'nav-item']" v-if="hasPermission('access-configuration') && getConfig('ip_filter')">
	    	<router-link to="/configuration/ip-filter" exact>
	    		<i class="feather icon-filter"></i> 
	    		<span class="menu-title">
	    			{{trans('ip_filter.ip_filter')}}
	    		</span>
	    	</router-link>
	    </li>
	    <li :class="[currentPage.includes('scheduled-job') ? activeClass : '', 'nav-item']" v-if="hasPermission('access-configuration') && getConfig('show_schedule_job_menu')">
	    	<router-link to="/configuration/scheduled-job" exact>
	    		<i class="feather icon-clock"></i> 
	    		<span class="menu-title">
	    			{{trans('general.scheduled_job')}}
	    		</span>
	    	</router-link>
	    </li>
	    <li :class="[currentPage.includes('backup') ? activeClass : '', 'nav-item']" v-if="hasPermission('access-configuration') && getConfig('show_backup_menu')">
	    	<router-link to="/backup" exact>
	    		<i class="feather icon-box"></i> 
	    		<span class="menu-title">
	    			{{trans('backup.backup')}}
	    		</span>
	    	</router-link>
	    </li>

		<li :class="[currentPage.includes('report') ? activeClass : '', 'nav-item']" v-if="hasPermission('list-report')">
	    	<router-link to="/report" exact>
	    		<i class="feather icon-database"></i> 
	    		<span class="menu-title">
	    			Report
	    		</span>
	    	</router-link>
	    </li>

		<li :class="[currentPage.includes('report') ? activeClass : '', 'nav-item']">
			<a href="http://developer.irs.sk.gov.ng" target="_blank">
				<i class="feather icon-book"></i> 
	    		<span class="menu-title">
	    			Documentation
	    		</span>
			</a>
	    </li>

    </ul>
</template>

<script>
	export default {
		data() {
			return {
				activeClass: 'active',
				userRole: ''
			}
		},
		mounted() {
			this.userRole = this.getRole()[0];
		},
		methods: {
			hasPermission(permission){
				return helper.hasPermission(permission);
			},
			hasRole(role){
				return helper.hasRole(role);
			},
			getConfig(config){
				return helper.getConfig(config);
			},
			getRole(role){
              return helper.getRole(role);
            },
		},

		computed: {
			currentPage(){
				return this.$route.path;
			},

			currentRouteName() {
				return this.$route.name;
			}
		}
	}
</script>
