import { render, staticRenderFns } from "./upload-image.vue?vue&type=template&id=ed70524c&"
import script from "./upload-image.vue?vue&type=script&lang=js&"
export * from "./upload-image.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/hamzakambaza/Desktop/Sites/soirs-edu/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ed70524c')) {
      api.createRecord('ed70524c', component.options)
    } else {
      api.reload('ed70524c', component.options)
    }
    module.hot.accept("./upload-image.vue?vue&type=template&id=ed70524c&", function () {
      api.rerender('ed70524c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/upload-image.vue"
export default component.exports