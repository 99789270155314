import { render, staticRenderFns } from "./staff.vue?vue&type=template&id=5d3bf3db&"
import script from "./staff.vue?vue&type=script&lang=js&"
export * from "./staff.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/hamzakambaza/Desktop/Sites/soirs-edu/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5d3bf3db')) {
      api.createRecord('5d3bf3db', component.options)
    } else {
      api.reload('5d3bf3db', component.options)
    }
    module.hot.accept("./staff.vue?vue&type=template&id=5d3bf3db&", function () {
      api.rerender('5d3bf3db', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/views/user/staff.vue"
export default component.exports