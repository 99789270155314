var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.proceed.apply(null, arguments)
          },
          keydown: function($event) {
            return _vm.paymentForm.errors.clear($event.target.name)
          }
        }
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 col-sm-4" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(_vm._s(_vm.trans("user.name")))
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.paymentForm.name,
                      expression: "paymentForm.name"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "is-invalid": _vm.paymentForm.errors.has("name") },
                  attrs: {
                    type: "text",
                    value: "",
                    name: "name",
                    placeholder: _vm.trans("user.name")
                  },
                  domProps: { value: _vm.paymentForm.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.paymentForm, "name", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("show-error", {
                  attrs: { "form-name": _vm.paymentForm, "prop-name": "name" }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-sm-4" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(_vm._s(_vm.trans("user.phone")))
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.paymentForm.phone,
                      expression: "paymentForm.phone"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "is-invalid": _vm.paymentForm.errors.has("phone") },
                  attrs: {
                    type: "text",
                    value: "",
                    name: "phone",
                    placeholder: _vm.trans("user.phone")
                  },
                  domProps: { value: _vm.paymentForm.phone },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.paymentForm, "phone", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("show-error", {
                  attrs: { "form-name": _vm.paymentForm, "prop-name": "phone" }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-sm-4" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(_vm._s(_vm.trans("user.email")))
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.paymentForm.email,
                      expression: "paymentForm.email"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "is-invalid": _vm.paymentForm.errors.has("email") },
                  attrs: {
                    type: "text",
                    value: "",
                    name: "email",
                    placeholder: _vm.trans("user.email")
                  },
                  domProps: { value: _vm.paymentForm.email },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.paymentForm, "email", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("show-error", {
                  attrs: { "form-name": _vm.paymentForm, "prop-name": "email" }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xl-12 col-md-12 col-12" }, [
            _c("table", { staticClass: "table table-sm" }, [
              _c("thead", { staticClass: "thead-light" }, [
                _c("tr", [
                  _c("th", { attrs: { width: "25%" } }, [_vm._v("MDA")]),
                  _vm._v(" "),
                  _c("th", { attrs: { width: "25%" } }, [_vm._v("Pay Item")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Amount")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Description")]),
                  _vm._v(" "),
                  _c("th", { staticClass: "table-option" }, [
                    _vm._v(_vm._s(_vm.trans("general.action")))
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.paymentForm.pay_items, function(pay_item, k) {
                  return _c("tr", { key: k }, [
                    _c(
                      "td",
                      { staticClass: "align-top" },
                      [
                        _c("v-select", {
                          class: {
                            "is-invalid": _vm.paymentForm.errors.has(
                              "pay_items." + [k] + ".mda_id"
                            )
                          },
                          attrs: {
                            options: _vm.mdas,
                            reduce: function(name) {
                              return name.id
                            },
                            label: "name"
                          },
                          on: { input: _vm.getPayItems },
                          model: {
                            value: pay_item.mda_id,
                            callback: function($$v) {
                              _vm.$set(pay_item, "mda_id", $$v)
                            },
                            expression: "pay_item.mda_id"
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.paymentForm,
                            "prop-name": "pay_items." + [k] + ".mda_id"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "align-top" },
                      [
                        _c("v-select", {
                          class: {
                            "is-invalid": _vm.paymentForm.errors.has(
                              "pay_items." + [k] + ".pay_item_id"
                            )
                          },
                          attrs: {
                            options: _vm.payItems,
                            reduce: function(name) {
                              return name.id
                            },
                            label: "name"
                          },
                          model: {
                            value: pay_item.pay_item_id,
                            callback: function($$v) {
                              _vm.$set(pay_item, "pay_item_id", $$v)
                            },
                            expression: "pay_item.pay_item_id"
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.paymentForm,
                            "prop-name": "pay_items." + [k] + ".pay_item_id"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "align-top" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: pay_item.amount,
                              expression: "pay_item.amount"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid": _vm.paymentForm.errors.has(
                              "pay_items." + [k] + ".amount"
                            )
                          },
                          attrs: {
                            type: "text",
                            value: "",
                            placeholder: "Amount"
                          },
                          domProps: { value: pay_item.amount },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(pay_item, "amount", $event.target.value)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.paymentForm,
                            "prop-name": "pay_items." + [k] + ".amount"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "align-top" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: pay_item.description,
                              expression: "pay_item.description"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid": _vm.paymentForm.errors.has(
                              "pay_items." + [k] + ".description"
                            )
                          },
                          attrs: {
                            type: "text",
                            value: "",
                            placeholder: "Description"
                          },
                          domProps: { value: pay_item.description },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                pay_item,
                                "description",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.paymentForm,
                            "prop-name": "pay_items." + [k] + ".description"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", { staticClass: "table-option align-top" }, [
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.trans("general.add"),
                              expression: "trans('general.add')"
                            }
                          ],
                          staticClass:
                            "btn btn-primary mr-1 mb-1 waves-effect waves-light btn-sm pull-right",
                          attrs: { type: "button" },
                          on: { click: _vm.addNewRow }
                        },
                        [_c("i", { staticClass: "feather icon-plus" })]
                      ),
                      _vm._v(" "),
                      k > 0
                        ? _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.trans("general.remove"),
                                  expression: "trans('general.remove')"
                                }
                              ],
                              staticClass:
                                "btn btn-danger mr-1 mb-1 waves-effect waves-light btn-sm pull-right",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.deleteRow(k, pay_item)
                                }
                              }
                            },
                            [_c("i", { staticClass: "feather icon-trash-2" })]
                          )
                        : _vm._e()
                    ])
                  ])
                }),
                0
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-sm-6" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(_vm._s(_vm.trans("user.tax_office_id")))
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.paymentForm.tax_office_id,
                        expression: "paymentForm.tax_office_id"
                      }
                    ],
                    staticClass: "form-control",
                    class: {
                      "is-invalid": _vm.paymentForm.errors.has("tax_office_id")
                    },
                    attrs: { name: "tax_office_id" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.paymentForm,
                          "tax_office_id",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v("Select Tax Office")
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.taxOffices, function(taxOffice) {
                      return _c(
                        "option",
                        { domProps: { value: taxOffice.id } },
                        [_vm._v(_vm._s(taxOffice.name))]
                      )
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _c("show-error", {
                  attrs: {
                    "form-name": _vm.paymentForm,
                    "prop-name": "tax_office_id"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-sm-6" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(_vm._s(_vm.trans("payment.gateway_id")))
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.paymentForm.gateway_id,
                        expression: "paymentForm.gateway_id"
                      }
                    ],
                    staticClass: "form-control",
                    class: {
                      "is-invalid": _vm.paymentForm.errors.has("gateway_id")
                    },
                    attrs: {
                      name: "gateway_id",
                      placeholder: "Select Payment \n                Gateway"
                    },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.paymentForm,
                          "gateway_id",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v("Select Payment Channel")
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.gateways, function(gateway) {
                      return _c("option", { domProps: { value: gateway.id } }, [
                        _vm._v(_vm._s(gateway.name))
                      ])
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _c("show-error", {
                  attrs: {
                    "form-name": _vm.paymentForm,
                    "prop-name": "gateway_id"
                  }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-8 offset-md-4" },
            [
              _c(
                "button-spinner",
                {
                  staticClass:
                    "btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right",
                  staticStyle: { height: "39px", color: "#fff" },
                  attrs: {
                    "is-loading": _vm.paymentForm.isLoading,
                    disabled: _vm.paymentForm.isLoading
                  }
                },
                [
                  _vm.id
                    ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
                    : _c("span", [_vm._v(_vm._s(_vm.trans("general.save")))])
                ]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass:
                    "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
                  attrs: { to: "/payment" }
                },
                [
                  _vm._v(
                    _vm._s(_vm.trans("general.cancel")) + "\n                "
                  )
                ]
              )
            ],
            1
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        staticStyle: { display: "none" },
        attrs: {
          id: "confirmPayment",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "paymentDetail",
          "aria-hidden": "true"
        }
      },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content" }, [
            _vm._m(0),
            _vm._v(" "),
            _vm.showConfirm
              ? _c("div", { staticClass: "modal-body" }, [
                  _c("h4", [_vm._v("Confirm Payment")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v("Transaction Reference: "),
                    _c("strong", [_vm._v(_vm._s(_vm.reference))])
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v("TAX ID: "),
                    _c("strong", [_vm._v(_vm._s(_vm.tax_id))])
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v("Phone NO: "),
                    _c("strong", [_vm._v(_vm._s(_vm.paymentForm.phone))])
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v("Email: "),
                    _c("strong", [_vm._v(_vm._s(_vm.paymentForm.email))])
                  ]),
                  _vm._v(" "),
                  _c(
                    "table",
                    { staticClass: "table" },
                    [
                      _vm._m(1),
                      _vm._v(" "),
                      _vm._l(_vm.payment_items, function(item) {
                        return _c("tr", [
                          _c("td", [_vm._v(_vm._s(item.mda.name))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(item.pay_item.name))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(item.amount))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(item.description))])
                        ])
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v("Total Amount: "),
                    _c("strong", [_vm._v(_vm._s(_vm.paymentForm.amount))])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-danger waves-effect text-left",
                  attrs: { type: "button", "data-dismiss": "modal" }
                },
                [_vm._v(_vm._s(_vm.trans("general.close")))]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary waves-effect",
                  attrs: { type: "button" },
                  on: { click: _vm.confirmPayment }
                },
                [_vm._v("Confirm")]
              )
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title", attrs: { id: "paymentDetail" } }, [
        _vm._v("Payment Confirmation")
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-hidden": "true"
          }
        },
        [_vm._v("×")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("MDA")]),
      _vm._v(" "),
      _c("th", [_vm._v("Item")]),
      _vm._v(" "),
      _c("th", [_vm._v("Amount")]),
      _vm._v(" "),
      _c("th", [_vm._v("Description")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }