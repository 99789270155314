var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-4 col-12 d-md-block d-none"
        },
        [
          _vm.userRole != "mda"
            ? _c("div", { staticClass: "form-group breadcrum-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-sm pull-right ml-1",
                    on: {
                      click: function($event) {
                        _vm.showCreatePanel = !_vm.showCreatePanel
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fas fa-plus" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "d-none d-sm-inline" }, [
                      _vm._v("Add New Developer")
                    ])
                  ]
                ),
                _vm._v(" "),
                !_vm.showFilterPanel
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-sm pull-right",
                        on: {
                          click: function($event) {
                            _vm.showFilterPanel = !_vm.showFilterPanel
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fas fa-filter" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "d-none d-sm-inline" }, [
                          _vm._v(_vm._s(_vm.trans("general.filter")))
                        ])
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _vm.userRole != "mda"
        ? _c("section", { attrs: { id: "basic-datatable" } }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("transition", { attrs: { name: "fade" } }, [
                    _vm.showCreatePanel
                      ? _c("div", { staticClass: "card border-bottom" }, [
                          _c("div", { staticClass: "card-header" }, [
                            _c("h4", { staticClass: "card-title" }, [
                              _vm._v("Add New Developer")
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "card-content" }, [
                            _c(
                              "div",
                              { staticClass: "card-body" },
                              [
                                _c("developer-form", {
                                  on: {
                                    completed: _vm.getDevelopers,
                                    cancel: function($event) {
                                      _vm.showCreatePanel = !_vm.showCreatePanel
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("transition", { attrs: { name: "fade" } }, [
                    _vm.showFilterPanel
                      ? _c("div", { staticClass: "card border-bottom" }, [
                          _c("div", { staticClass: "card-header" }, [
                            _c("h4", { staticClass: "card-title" }, [
                              _vm._v(
                                "\n                                 " +
                                  _vm._s(_vm.trans("general.filter")) +
                                  "\n                             "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "card-content" }, [
                            _c("div", { staticClass: "card-body" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v(
                                        _vm._s(_vm.trans("general.keyword"))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.filterDeveloperForm.keyword,
                                          expression:
                                            "filterDeveloperForm.keyword"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { name: "keyword" },
                                      domProps: {
                                        value: _vm.filterDeveloperForm.keyword
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.filterDeveloperForm,
                                            "keyword",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _vm.showFilterPanel
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
                                      on: {
                                        click: function($event) {
                                          _vm.showFilterPanel = !_vm.showFilterPanel
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.trans("general.cancel"))
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ])
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-header" }, [
                      _vm.developers.total
                        ? _c(
                            "h4",
                            { staticClass: "card-title" },
                            [
                              _c("sort-by", {
                                attrs: {
                                  "order-by-options": _vm.orderByOptions,
                                  "sort-by": _vm.filterDeveloperForm.sort_by,
                                  order: _vm.filterDeveloperForm.order
                                },
                                on: {
                                  updateSortBy: function(value) {
                                    _vm.filterDeveloperForm.sort_by = value
                                  },
                                  updateOrder: function(value) {
                                    _vm.filterDeveloperForm.order = value
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-content" }, [
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _vm.developers.total
                            ? _c("div", { staticClass: "table-responsive" }, [
                                _c("table", { staticClass: "table" }, [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", [_vm._v("School")]),
                                      _vm._v(" "),
                                      _c("th", [_vm._v("Gateway")]),
                                      _vm._v(" "),
                                      _c("th", [_vm._v("API Key")]),
                                      _vm._v(" "),
                                      _c("th", [_vm._v("Public Key")]),
                                      _vm._v(" "),
                                      _c("th", [_vm._v("Secret Key")]),
                                      _vm._v(" "),
                                      _c("th", [_vm._v("Merchant ID")]),
                                      _vm._v(" "),
                                      _c("th", [_vm._v("Fallback URL")]),
                                      _vm._v(" "),
                                      _c("th", [_vm._v("Response URL")]),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        { staticClass: "table-option" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.trans("general.action"))
                                          )
                                        ]
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.developers.data, function(
                                      developer
                                    ) {
                                      return _c("tr", [
                                        _c("td", {
                                          domProps: {
                                            textContent: _vm._s(
                                              developer.user.name
                                            )
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("td", {
                                          domProps: {
                                            textContent: _vm._s(
                                              developer.gateway.name
                                            )
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("td", {
                                          domProps: {
                                            textContent: _vm._s(
                                              developer.api_key
                                            )
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("td", {
                                          domProps: {
                                            textContent: _vm._s(
                                              developer.public_key
                                            )
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("td", {
                                          domProps: {
                                            textContent: _vm._s(
                                              developer.secret_key
                                            )
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("td", {
                                          domProps: {
                                            textContent: _vm._s(
                                              developer.merchant_id
                                            )
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("td", {
                                          domProps: {
                                            textContent: _vm._s(
                                              developer.fallback_url
                                            )
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("td", {
                                          domProps: {
                                            textContent: _vm._s(
                                              developer.response_url
                                            )
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { staticClass: "table-option" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "btn-group" },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value: "Edit Developer",
                                                        expression:
                                                          "'Edit Developer'"
                                                      }
                                                    ],
                                                    staticClass:
                                                      "btn btn-info btn-sm",
                                                    on: {
                                                      click: function($event) {
                                                        $event.preventDefault()
                                                        return _vm.editDeveloper(
                                                          developer
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "feather icon-edit"
                                                    })
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "confirm",
                                                        rawName: "v-confirm",
                                                        value: {
                                                          ok: _vm.confirmDelete(
                                                            developer
                                                          )
                                                        },
                                                        expression:
                                                          "{ok: confirmDelete(developer)}"
                                                      },
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value:
                                                          "Delete Developer",
                                                        expression:
                                                          "'Delete Developer'"
                                                      }
                                                    ],
                                                    key: developer.id,
                                                    staticClass:
                                                      "btn btn-danger btn-sm"
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "feather icon-trash-2"
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    }),
                                    0
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.developers.total
                            ? _c(
                                "module-info",
                                {
                                  attrs: {
                                    module: "general",
                                    title: "module_info_title",
                                    description: "module_info_description",
                                    icon: "check-circle"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "btn" }, slot: "btn" },
                                    [
                                      !_vm.showCreatePanel
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-primary btn-md",
                                              on: {
                                                click: function($event) {
                                                  _vm.showCreatePanel = !_vm.showCreatePanel
                                                }
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "feather icon-plus"
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.trans("general.add_new")
                                                  )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("pagination-record", {
                            attrs: {
                              "page-length":
                                _vm.filterDeveloperForm.page_length,
                              records: _vm.developers
                            },
                            on: {
                              "update:pageLength": function($event) {
                                return _vm.$set(
                                  _vm.filterDeveloperForm,
                                  "page_length",
                                  $event
                                )
                              },
                              "update:page-length": function($event) {
                                return _vm.$set(
                                  _vm.filterDeveloperForm,
                                  "page_length",
                                  $event
                                )
                              },
                              updateRecords: _vm.getDevelopers
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.userRole == "mda"
        ? _c("section", { attrs: { id: "ApiKeyPage" } }, [
            _c("div", { staticClass: "card" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _c("p", { staticClass: "card-text" }, [
                  _vm._v(
                    "\n                             An API key is a simple encrypted string that identifies an application without any principal. They are useful\n                             for accessing public data anonymously, and are used to associate API requests with your project for quota and\n                             billing.\n                         "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row gy-2" }, [
                  _c("h4", { staticClass: "card-title" }, [_vm._v("Monnify")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-6" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "bg-light-secondary position-relative rounded p-2"
                      },
                      [
                        _vm._m(2),
                        _vm._v(" "),
                        _c(
                          "h6",
                          {
                            staticClass: "d-flex align-items-center fw-bolder"
                          },
                          [
                            _c("span", { staticClass: "me-50" }, [
                              _vm._v(_vm._s(_vm.monnifyApiKey))
                            ]),
                            _vm._v(" "),
                            _vm._m(3)
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.createdAt))])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-6" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "bg-light-secondary position-relative rounded p-2"
                      },
                      [
                        _vm._m(4),
                        _vm._v(" "),
                        _c(
                          "h6",
                          {
                            staticClass: "d-flex align-items-center fw-bolder"
                          },
                          [
                            _c("span", { staticClass: "me-50" }, [
                              _vm._v(_vm._s(_vm.monnifySecretKey))
                            ]),
                            _vm._v(" "),
                            _vm._m(5)
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.createdAt))])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-6" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "bg-light-secondary position-relative rounded p-2"
                      },
                      [
                        _vm._m(6),
                        _vm._v(" "),
                        _c(
                          "h6",
                          {
                            staticClass: "d-flex align-items-center fw-bolder"
                          },
                          [
                            _c("span", { staticClass: "me-50" }, [
                              _vm._v(_vm._s(_vm.monnifyMerchantId))
                            ]),
                            _vm._v(" "),
                            _vm._m(7)
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.createdAt))])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-6" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "bg-light-secondary position-relative rounded p-2"
                      },
                      [
                        _vm._m(8),
                        _vm._v(" "),
                        _c(
                          "h6",
                          {
                            staticClass: "d-flex align-items-center fw-bolder"
                          },
                          [
                            _c("span", { staticClass: "me-50" }, [
                              _vm._v(_vm._s(_vm.schoolCode))
                            ]),
                            _vm._v(" "),
                            _vm._m(9)
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.createdAt))])
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row gy-2" }, [
                  _c("h4", { staticClass: "card-title" }, [_vm._v("Remita")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-6" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "bg-light-secondary position-relative rounded p-2"
                      },
                      [
                        _vm._m(10),
                        _vm._v(" "),
                        _c(
                          "h6",
                          {
                            staticClass: "d-flex align-items-center fw-bolder"
                          },
                          [
                            _c("span", { staticClass: "me-50" }, [
                              _vm._v(_vm._s(_vm.remitaApiKey))
                            ]),
                            _vm._v(" "),
                            _vm._m(11)
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.createdAt))])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-6" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "bg-light-secondary position-relative rounded p-2"
                      },
                      [
                        _vm._m(12),
                        _vm._v(" "),
                        _c(
                          "h6",
                          {
                            staticClass: "d-flex align-items-center fw-bolder"
                          },
                          [
                            _c("span", { staticClass: "me-50" }, [
                              _vm._v(_vm._s(_vm.remitaSecretKey))
                            ]),
                            _vm._v(" "),
                            _vm._m(13)
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.createdAt))])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-6" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "bg-light-secondary position-relative rounded p-2"
                      },
                      [
                        _vm._m(14),
                        _vm._v(" "),
                        _c(
                          "h6",
                          {
                            staticClass: "d-flex align-items-center fw-bolder"
                          },
                          [
                            _c("span", { staticClass: "me-50" }, [
                              _vm._v(_vm._s(_vm.remitaMerchantId))
                            ]),
                            _vm._v(" "),
                            _vm._m(15)
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.createdAt))])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-6" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "bg-light-secondary position-relative rounded p-2"
                      },
                      [
                        _vm._m(16),
                        _vm._v(" "),
                        _c(
                          "h6",
                          {
                            staticClass: "d-flex align-items-center fw-bolder"
                          },
                          [
                            _c("span", { staticClass: "me-50" }, [
                              _vm._v(_vm._s(_vm.remitaPuplicKey))
                            ]),
                            _vm._v(" "),
                            _vm._m(17)
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.createdAt))])
                      ]
                    )
                  ])
                ])
              ])
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "content-header-left col-md-8 col-12 mb-2" },
      [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v("Developers")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _c("li", { staticClass: "breadcrumb-item" }, [
                  _c("a", { attrs: { href: "/" } }, [_vm._v("Home")])
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item" }, [
                  _c("a", { attrs: { href: "/" } }, [_vm._v("Settings")])
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v("Developers")
                ])
              ])
            ])
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-title" }, [_vm._v("API Key List & Access")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex align-items-center flex-wrap" }, [
      _c("h4", { staticClass: "mb-1 me-1" }, [_vm._v("API Key")]),
      _vm._v(" "),
      _c("span", { staticClass: "badge badge-light-primary mb-1" }, [
        _vm._v("Read Only")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v(" "),
      _c("i", { staticClass: "feather icon-copy cursor-pointer" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex align-items-center flex-wrap" }, [
      _c("h4", { staticClass: "mb-1 me-1" }, [_vm._v("Secret Key")]),
      _vm._v(" "),
      _c("span", { staticClass: "badge badge-light-primary mb-1" }, [
        _vm._v("Read Only")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v(" "),
      _c("i", { staticClass: "feather icon-copy cursor-pointer" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex align-items-center flex-wrap" }, [
      _c("h4", { staticClass: "mb-1 me-1" }, [
        _vm._v("Merchant ID/Contract Code")
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "badge badge-light-primary mb-1" }, [
        _vm._v("Read Only")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v(" "),
      _c("i", { staticClass: "feather icon-copy cursor-pointer" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex align-items-center flex-wrap" }, [
      _c("h4", { staticClass: "mb-1 me-1" }, [_vm._v("School Code")]),
      _vm._v(" "),
      _c("span", { staticClass: "badge badge-light-primary mb-1" }, [
        _vm._v("Read Only")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v(" "),
      _c("i", { staticClass: "feather icon-copy cursor-pointer" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex align-items-center flex-wrap" }, [
      _c("h4", { staticClass: "mb-1 me-1" }, [_vm._v("API Key")]),
      _vm._v(" "),
      _c("span", { staticClass: "badge badge-light-primary mb-1" }, [
        _vm._v("Read Only")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v(" "),
      _c("i", { staticClass: "feather icon-copy cursor-pointer" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex align-items-center flex-wrap" }, [
      _c("h4", { staticClass: "mb-1 me-1" }, [_vm._v("Secret Key")]),
      _vm._v(" "),
      _c("span", { staticClass: "badge badge-light-primary mb-1" }, [
        _vm._v("Read Only")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v(" "),
      _c("i", { staticClass: "feather icon-copy cursor-pointer" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex align-items-center flex-wrap" }, [
      _c("h4", { staticClass: "mb-1 me-1" }, [
        _vm._v("Merchant ID/Contract Code")
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "badge badge-light-primary mb-1" }, [
        _vm._v("Read Only")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v(" "),
      _c("i", { staticClass: "feather icon-copy cursor-pointer" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex align-items-center flex-wrap" }, [
      _c("h4", { staticClass: "mb-1 me-1" }, [_vm._v("Public Key")]),
      _vm._v(" "),
      _c("span", { staticClass: "badge badge-light-primary mb-1" }, [
        _vm._v("Read Only")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v(" "),
      _c("i", { staticClass: "feather icon-copy cursor-pointer" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }